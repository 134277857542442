import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import socket from '../Components/socket'
import { apiRequest } from '../../apiService/allApi'
const Pay = () => {
  const access_token = localStorage.getItem('token')
// BASE URL
 
const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {

  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}
// +++++++++++++++++++++++++++++ 
  const { search } = useLocation()
  const history = useHistory()
  useEffect(async() => {
    var myHeaders = new Headers()

    // console.log(search.split("=")[1])
    myHeaders.append('Authorization', `Bearer ${access_token}`)

    // var requestOptions = {
    //   method: 'GET',
    //   headers: myHeaders,
    //   redirect: 'follow'
    // }

    // fetch(
    //   baseUrl+`phonpay-request-status?txnId=${
    //     search.split('=')[1]
    //   }`,
    //   requestOptions
    // )
    const result = await apiRequest(`phonpay-request-status?txnId=${search.split('=')[1]}`, "GET",null,myHeaders)

      // .then(response => response.json())
      // .then(result => {
        console.log(result)
        if (result) {
          Swal.fire({
            title: result.message,
            icon: result.message == 'Payment Failed' ? 'error' : 'success',
            confirmButtonText: 'OK'
          })
          if (result.code == 'PAYMENT_SUCCESS') {
            socket.emit('getprofile')
          }
          history.push('/addcase')
        }
      })
      // .catch(error => console.log('error', error))
  // }, [])
  return <div></div>
}

export default Pay
