import axios from "axios";
import React, { useRef, useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import css from "../css/Addcase.module.css";
import Compressor from "compressorjs";
import css1 from "../css/Pan.module.css";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/Loader.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/viewGame1.css";
import "../css/layout.css";
import buttonIcon from '../../assets/images/youtube.png';
import Swal from "sweetalert2";
import Header from "../Components/Header";
import socket from "../Components/socket";
import "../css/landing.css";
import Modal from "react-bootstrap/Modal";
import { BsArrowRight, BsQrCodeScan } from "react-icons/bs";
import DownloadLink from "react-download-link";
import Spinner from "react-bootstrap/Spinner";
import { LanguageContext } from "../Components/LanguageContext";
import { useTheme } from "../../ThemeContext";
import { apiRequest } from "../../apiService/allApi";

const Addcase = ({ walletUpdate }) => {
  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  // +++++++++++++++++++++++++++++

  const history = useHistory();
  let method = useRef();
  let checkInterval;
  const [userAllData, setUserAllData] = useState();

  const access_token = localStorage.getItem("token");

  const [isLoading1, setIsloading1] = useState(false);
  const [global, setGlobal] = useState(100);
  const [next, setNext] = useState(1);
  const [process1, setProcess] = useState(false);
  const [isMobile, setMobile] = useState(false);

  const [isCashFreeActive, setCashFreeActive] = useState(true);
  const [isPhonePayActive, setIsPhonePayActive] = useState(false);
  const [isAirPayActive, setIsAirPayActive] = useState(false);
  const [isKvmPayActive, setIsKvmPayActive] = useState(false);
  const [isUpiGateway, setUpiGateway] = useState(false);
  const [isMyPayActive, setIsMyPayActive] = useState(false);
  const [isPineLabActive, setPineLabActive] = useState(false);
  const [isRazorPayActive, setRazorPayActive] = useState(false);
  const [isDecentroActive, setDecentroActive] = useState(false);
  const [isHoadaPaypayInActive, setHoadaPaypayInActive] = useState(false);
  const [ManualPaymentdata, setManualPaymentdata] = useState([]);

  const [manualData, setManualData] = useState([]);
  const [PayOneImage, setPayOneImage] = useState("");
  const [PayTwoImage, setPayTwoImage] = useState("");
  const [PayThreeImage, setPayThreeImage] = useState("");
  const [PayFourImage, setPayFourImage] = useState("");
  const [PayFiveImage, setPayFiveImage] = useState("");

  // hooks for payment url
  const [PayNow1Url, setPaynow1Url] = useState("");
  const [PayNow2Url, setPaynow2Url] = useState("");
  const [PayNow3Url, setPaynow3Url] = useState("");
  const [PayNow4Url, setPaynow4Url] = useState("");
  const [PayNow5Url, setPaynow5Url] = useState("");

  const [show_won, setShow_won] = useState(false);
  const handleClose_won = () => setShow_won(false);
  const [scrnshot, setScrnshot] = useState(null);
  const [fecthStatus, setFecthStatus] = useState();
  const [scrnshot1, setScrnshot1] = useState("");
  const handleShow_won = () => setShow_won(true);

  const [qrCode, setQrCode] = useState();
  const [walletOption, setWalletOption] = useState("airtel");
  const [bankCode, setBankCode] = useState(3003);

  const [account_mail_id, setAccount_mail_id] = useState();
  const [account_name, setAccount_name] = useState();
  const [accountPhone, setAccountPhone] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [minLimit, setMinLimit] = useState(null);
  const [maxLimit, setMaxLimit] = useState(null);
  const [amount, setamount] = useState("");
  const [utr, setutr] = useState("");

  // console.log(maxLimit)

  const addsetting = localStorage.getItem("sitSetting");
  const addCaseSetting = JSON.parse(addsetting);
  

  // Hoada Hooks

  const [hoadaPay, setHoadaPay] = useState(false);

  useEffect(() => {
    setMinLimit(addCaseSetting.depositlimitMin);
    setMaxLimit(addCaseSetting.depositlimitMax);

    if (!addCaseSetting.isDeposit) {
      <script>

        document.getElementById("addcase").style.display = "none";
        document.getElementById("depositClose").style.display = "block";
      </script>
    }
  }, []);

  const handleNext = () => {
    if (global < minLimit || global > maxLimit) {
      Swal.fire({
        title: `Minimum amount should be greater than ${minLimit} and maximum amount should be less than ${maxLimit}`,

        confirmButtonText: "OK",
        content: "custom-swal-content",
      });
    } else {
      setNext(2);
    }
  };

  //Function to load razorpay script for the display of razorpay payment SDK.
  function loadRazorpayScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  //function will get called when clicked on the pay button.
  async function displayRazorpayPaymentSdk(
    channel,
    method,
    upiMethod,
    razorpay
  ) {
    const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. please check are you online?");
      return;
    }
    //setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    // creating a new order and sending order ID to backend
    // const response = await axios.post(
    //   baseUrl + "user/razorpay_order",
    //   {
        // amount: global,
        // channel: channel,
        // payment_method: method,
        // provider: walletOption,
        // bankCode: bankCode,
        // account_name: account_name,
        // payment_gatway: razorpay,
    //   },
    //   { headers }
    // );
    let alldata = {
      amount: global,
      channel: channel,
      payment_method: method,
      provider: walletOption,
      bankCode: bankCode,
      account_name: account_name,
      payment_gatway: razorpay,
    }

    const response = await apiRequest('user/razorpay_order',"POST" , alldata, headers)

    if (!response) {
      alert("Server error. please check are you onlin?");
      return;
    }

    // Getting the order details back
    let order_id = response.data.txnID;
    let order_token = response.data.orderdata.id;
    const data = response.data.orderdata;
    const options = {
      key: "rzp_live_hmxBSXgEqtBqJq",
      name: "Rk Ludo",
      description: "Skill Based Game Tournament",
      order_id: data.id,
      prefill: {
        name: account_name,
        email: account_mail_id,
        contact: accountPhone,
      },
      handler: async (response) => {
        //console.log(response)
        try {
          const paymentId = response.razorpay_payment_id;

          checkrazorpaydeposit(order_id, order_token, "SUCCESS", paymentId);
        } catch (err) {
          checkrazorpaydeposit(order_id, order_token, "FAILED");
          console.log(err);
        }
      },
      theme: {
        color: "#686CFD",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

   const checkrazorpaydeposit = async (
    order_id,
    order_token,
    order_status,
    paymentId
  ) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
     // axios
      // .post(
      //   baseUrl + `razorpaydesposit/response`,
      //   { order_id, order_token, order_status, paymentId },
      //   { headers }
      // )
      let alldeta2={
        order_id, order_token, order_status, paymentId 
      }

      const response = await apiRequest('user/razorpay_order',"POST" ,alldeta2, headers)


      // .then((res) => {
        const icon = response.data.status == "PAID" ? "success" : "danger";
        const title =

        response.data.status == "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        history.push("/");
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);
      // })
      // .catch((e) => {
        // if (e.response.status == 401) {
        //   localStorage.removeItem("token");
        //   localStorage.removeItem("token");
        //   window.location.reload();
        // }
      // });
  };

   // upigateway
   const upigateway = async () => {

    setProcess(true)
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`,
    }

    // axios
    //   .post(
    //     baseUrl + `user/depositeupi?amount=${global.toFixed(2)}`,
    //     {
    //       amount: global,
    //     },
    //     { headers },
    //   )

    const respone = await apiRequest( `user/depositeupi?amount=${global.toFixed(2)}`,"POST", { amount: global}, headers)

      // .then((res) => {
        if (respone.data.data.status) {
          // console.log(res.data.data.data.payment_url)
          // console.log(res.data.data.data.order_id)
          let order_id = respone.data.txnID
          let order_token = respone.data.data.data.order_id
          //setProcess(false);
          window.open(respone.data.data.data.payment_url)
          setTimeout(() => {
            checkupideposit(order_id, order_token)
            setProcess(false)
          }, 60000)
        } else {
          setProcess(false)
          Swal.fire({
            title: respone.data.data.msg,
            icon: 'danger',
            confirmButtonText: 'OK',
          })
        }
      // // })
      // .catch((e) => {
      //   alert(e)
      // })
 
}

  // PHONEPAY GETWAY API

  const fetchPaymentUrl = async () => {
    const access_token = localStorage.getItem('token')
    const headers ={
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    }
    try {
      setIsLoading(true);

      // const response = await fetch(
      //   baseUrl + `phonpay-request?amount=${global}`,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${access_token}`,
      //     },
      //   }
      // );
      const respone = await apiRequest(`phonpay-request?amount=${global}`,"GET", null,headers)

      // const data = await response.json();
      if (respone) {
        setIsLoading(false);

        window.location.href = respone.data.instrumentResponse.redirectInfo.url;
      }
    } 
    catch (error) {
      console.error("Error fetching payment URL:", error);
      setIsLoading(false);
    }
  };
  // AIRPAY
  const airpay = async () => {
    const access_token = localStorage.getItem('token')
    const headers ={
      "Content-Type": "application/json",
       Authorization: `Bearer ${access_token}`,
    }
    try {
      setIsLoading(true);

      // const response = await fetch(
      //   baseUrl + `airpay-request?amount=${global}`,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${access_token}`,
      //     },
      //   }
      // );
      const respone = await apiRequest( `airpay-request?amount=${global}`,"GET" ,null, headers)

      // const data = await response.json();
      if (respone) {
        setIsLoading(false);

        window.location.href = respone.data.instrumentResponse.redirectInfo.url;
      }
    } catch (error) {
      console.error("Error fetching payment URL:", error);
      setIsLoading(false);
    }
  };
  // HOADA PAY GETWAY API
  const Hoada = async () => {
    const headers ={
      "Content-Type": "application/json",
       Authorization: `Bearer ${access_token}`,
    }
    try {
      setIsLoading(true);

      // const response = await fetch(
      //   baseUrl + `haodapay-request-payin?amount=${global}`,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${access_token}`,
      //     },
      //   }
      // );

      const respone = await apiRequest(`haodapay-request-payin?amount=${global}`, "GET" , null , headers)

      // const data = await response.json();
      if (respone) {
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);

        const isMobile = window.innerWidth < 768;

        if (isMobile) {
          //  window.location.href = data.data.data.intent_link;
          window.location.href = respone.data.data.payment_link;
        } else {
          window.location.href = respone.data.data.payment_link;
        }
      }
    } catch (error) {
      console.error("Error fetching payment URL:", error);
      setIsLoading(false);
    }
  };

  // MYPAY GETWAY API
  // const myPay = () => {
  //   setProcess(true);
  //   const access_token = localStorage.getItem("token");
  //   const headers = {
  //     Authorization: `Bearer ${access_token}`,
  //   };
  //   axios
  //     .post(
  //       baseUrl + `mypay/payin`,
  //       {
  //         amount: global,
  //       },
  //       { headers }
  //     )
  //     .then((res) => {
  //       // console.log(res.data?.data?.qrstring, "res");
  //       setProcess(false);
  //       if (res.data.status == false) {
  //         Swal.fire({
  //           title: res.data.msg,
  //           icon: "danger",
  //           confirmButtonText: "OK",
  //         });
  //       } else if (res.data) {
  //         let order_id = res.data.txnID;
  //         let order_token = res.data.clientrefid;
  //         //setProcess(false);
  //         window.open(`${res.data?.data?.qrstring}&am=${global}`);
  //         setTimeout(() => {
  //           checkupideposit(order_id, order_token);
  //           setProcess(false);
  //         }, 30000);
  //       } else {
  //         setProcess(false);
  //         Swal.fire({
  //           title: res.data.status,
  //           icon: "danger",
  //           confirmButtonText: "OK",
  //         });
  //       }
  //     })
  //     .catch((e) => {
  //       setProcess(false);
  //       console.log(e, "error");

  //       if (e.response.status == 400 || e.response.status == 429) {
  //         Swal.fire({
  //           title: e.response.msg,
  //           icon: "warning",
  //           confirmButtonText: "OK",
  //         });
  //       } else {
  //         Swal.fire({
  //           title: "error!! Please refresh and connect to admin",
  //           icon: "warning",
  //           confirmButtonText: "OK",
  //         });
  //       }
  //       alert(e);
  //     });
  // };
  const myPay =  async () => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    // axios
    //   .post(
    //     baseUrl + `kvmpay/payin`,
    //     {
    //       amount: global,
    //     },
    //     { headers }
    //   )
    const respone = await apiRequest( `kvmpay/payin`, "POST" ,{amount: global} , headers)

      // .then((res) => {
        console.log(respone, "res");
        setProcess(false);
        if (respone.data.status == false) {
          Swal.fire({
            title: respone.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        } else if (respone.data) {
          let order_id = respone.data.txnID;
          let order_token = respone.data.paymentReferenceId;
          //setProcess(false);
          window.open(`${respone?.data?.qrcodE_STRING}&am=${global}`);
          setTimeout(() => {
            checkupideposit(order_id, order_token);
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: respone.data.status,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      // })
      // .catch((e) => {
      //   setProcess(false);
      //   console.log(e, "error");

      //   if (e.response.status == 400 || e.response.status == 429) {
      //     Swal.fire({
      //       title: e.response.msg,
      //       icon: "warning",
      //       confirmButtonText: "OK",
      //     });
      //   } else {
      //     Swal.fire({
      //       title: "error!! Please refresh and connect to admin",
      //       icon: "warning",
      //       confirmButtonText: "OK",
      //     });
      //   }
      //   alert(e);
      // });
  };

  const checkupideposit   =  async (order_id, order_token) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    // axios
    //   .post(
    //     baseUrl + `depositupipay/response`,
    //     { order_id, order_token },
    //     { headers }
    //   )

    const respone = await apiRequest( `depositupipay/response`,"POST", { order_id, order_token }, headers)

      // .then((res) => {
        const icon = respone.data.status == "PAID" ? "success" : "danger";
        const title =
        respone.data.status == "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        history.push("/");
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);
      // })
      // .catch((e) => {
      //   if (e.response.status == 401) {
      //     localStorage.removeItem("token");
      //     localStorage.removeItem("token");
      //     window.location.reload();
      //   }
      // });
  };

  //use for cashfree gatway
  const deposit = async (channel, method, upiMethod) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
   
    
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    // axios
    //   .post(
    //     baseUrl + `user/deposite`,
    //     {
    //       amount: global,
    //       channel: channel,
    //       payment_method: method,
    //       provider: walletOption,
    //       bankCode: bankCode,
    //     },
    //     { headers }
    //   )
    const alldata = {
      amount: global,
      channel: channel,
      payment_method: method,
      provider: walletOption,
      bankCode: bankCode,
    }
    const respone = await apiRequest(`user/deposite`, "POST",alldata, headers)
      // .then((res) => {

        if (respone.data.data.payment_method == "app") {
          window.location.href = respone.data.data.data.url;
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            respone.data.txnID
          );
        } else if (
          respone.data.data.channel == "link" &&
          respone.data.data.payment_method == "upi"
        ) {
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            respone.data.txnID
          );
          window.location.href = respone.data.data.data.payload[upiMethod];
        } else if (
          respone.data.data.channel == "qrcode" &&
          respone.data.data.payment_method == "upi"
        ) {
          setQrCode(respone.data.data.data.payload.qrcode);
          setProcess(false);
          setNext(3);
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            respone.data.txnID
          );
        }
      // })
      // .catch((e) => {
      //   alert(e);
      // });
  };

  const checkout = async (paymentID) => {
    socket.emit("getprofile");

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    // axios
    //   .post(
    //     baseUrl + `checkout/deposite/txn`,
    //     { txnID: paymentID },
    //     { headers }
    //   )

    const respone = await apiRequest(`checkout/deposite/txn`,"POST" ,{ txnID: paymentID },headers)
      // .then((res) => {
        // alert(res.data.txnStatus)
        if (respone.data.txnStatus == "PAID") {
          walletUpdate();
          clearInterval(checkInterval);
          Swal.fire({
            title: respone.data.msg,
            icon: "success",
            confirmButtonText: "OK",
          });

          setProcess(false);
          setNext(1);
          history.push("/");
        } else if (respone.data.txnStatus == "FAILED") {
          walletUpdate();
          clearInterval(checkInterval);
          Swal.fire({
            title: respone.data.msg,
            icon: "error",
            confirmButtonText: "OK",
          });

          setProcess(false);
          setNext(1);
        }
      // })
      // .catch((e) => {
      //   console.log(e);
      // });
  };


  useEffect( async ()  =>  {
    let access_token = localStorage.getItem("token");
    const headers  =  {
      Authorization: `Bearer ${access_token}`,
    };
    // axios
    //   .get(baseUrl + `me`, { headers })

    const respone = await apiRequest( "me","GET", null, headers)


      // .then((res) => {
        setUserAllData(respone.data);
        setAccount_mail_id(respone.data.Email);
        setAccount_name(respone.data.holder_name);
        setAccountPhone(respone.data.Phone);
      // })
      // .catch((e) => {
      //   if (e.response.status == 401) {
      //     localStorage.removeItem("token");
      //     localStorage.removeItem("token");
      //     window.location.reload();
      //     history.push("/login");
      //   }
      // });

    // axios
    //   .get(baseUrl + `website/setting`)

    const res = await apiRequest( `website/setting`, "GET",null, )

      // .then((res) => {
        console.log(res?.data, "site");
        setCashFreeActive(res.data.isCashFreeActive);
        setRazorPayActive(res.data.isRazorPayActive);
        setDecentroActive(res.data.isDecentroActive);
        setPineLabActive(res.data.isPineLabActive);
        setIsPhonePayActive(res.data.isPhonePayActive);
        setIsAirPayActive(res.data.isAirPayActive);
        setIsMyPayActive(res.data.isMyPayActive);
        setIsKvmPayActive(res.data.isKvmPayActive);
        // setUpiGateway(res.data.isUpiGateway);
        setHoadaPaypayInActive(res.data.isHaodaPayPayInActive);
      // })
      // .catch((e) => {
      //   setCashFreeActive(false);
      //   setRazorPayActive(false);
      //   setDecentroActive(false);
      //   setPineLabActive(false);
      //   setIsPhonePayActive(false);
      //   setIsAirPayActive(false);
      //   setIsMyPayActive(false);
      //   setIsKvmPayActive(false);
      //   setUpiGateway(false);
      // });

    if (
      /Android|webOS|iPhone|iPad|iPod|#fffBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setMobile(true);
    }
  }, []);

  // QR CODE IMAGES DOWNLOAD ---------------------------------------------------

  const handleImageDownload = (item) => {
    const link = document.createElement("a");
    link.href = item;
    link.download = "QR-Code.png"; // You can specify the downloaded file name here
    link.click();
  };
  // clear Image
  const clearImage = (e) => {
    setScrnshot1(null);
    setScrnshot(null);
  };
  // MANUAL PAYMENTS METHODS API

  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]));
    const image = e.target.files[0];
    if (image && image.size / 1000 > 300) {
      console.log(" compression");
      new Compressor(image, {
        quality: 0.6,
        success(compressedResult) {
          if (compressedResult) {
            setScrnshot(compressedResult);
          } else {
            setScrnshot(image);
          }
        },
      });
    } else {
      setScrnshot(e.target.files[0]);
    }
  };

  const ManualPayment = async () => {
    const access_token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${access_token}`);

    // var requestOptions = {
    //   method: "GET",
    //   headers: myHeaders,
    //   redirect: "follow",
    // };

    // fetch(baseUrl + "gatewaysettings/data", requestOptions)
    const result = await apiRequest ("gatewaysettings/data" ,"GET",null,myHeaders)
    // console.log(result?.data?.isUpiGateway)

      // .then((response) => response.json())
      // .then((result) => {
        setManualData(result.data );
        setPaynow1Url(result?.data?.paynow1Url);
        setPayOneImage(result?.data?.isPayOneImage);
        setPayTwoImage(result?.data?.isPayTwoImage);
        setPayThreeImage(result?.data?.isPayThreeImage);
        setPayFourImage(result?.data?.isPayFourImage);
        setPayFiveImage(result?.data?.isPayFiveImage);
        setIsMyPayActive(result?.data?.isMypayInActive)
        setIsKvmPayActive(result?.data?.isKvmPayActive)
        setUpiGateway(result?.data?.isUpiGateway)
      // })
      // .catch((error) => {
      //   console.log("error", error);
      // });
  };

  useEffect(() => {
    let isMounted = true;
  
    ManualPayment();
  
    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, []);

  

  // getImage URl
  const [ImageUrl, setImageUrl] = useState("");
  const [PaymentLink, setPaymentLink] = useState("");

  const getImageUrl = (image, url) => {
   
    setImageUrl(image);
    setPaymentLink(url);
  };

  // code link payment

  const copyCode = (e) => {
    navigator.clipboard.writeText(PayNow1Url);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Link Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };
  const copyCode1 = (e) => {
    navigator.clipboard.writeText(manualData?.acNo);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Account Number Copy",
      showConfirmButton: false,
      timer: 1200,
    });
  };
  const copyCode2 = (e) => {
    navigator.clipboard.writeText(manualData?.acName);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Name Copy",
      showConfirmButton: false,
      timer: 1200,
    });
  };
  const copyCode3 = (e) => {
    navigator.clipboard.writeText(manualData?.ifsccode);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "IFSC Code Copy",
      showConfirmButton: false,
      timer: 1200,
    });
  };
 


  
  // kvm pay

  const kvmpay =  async () => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    // axios
    //   .post(
    //     baseUrl + 'kvmpay/payin',
    //     {
    //       amount: global,
    //     },
    //     { headers }
    //   )
    const res = await apiRequest('kvmpay/payin',"POST",{amount: global},headers)

      // .then((res) => {
        console.log(res, "res");
        setProcess(false);
        if (res.data.status == false) {
          Swal.fire({
            title: res.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        } else if (res.data) {
          let order_id = res.data.txnID;
          let order_token = res.data.paymentReferenceId;
          //setProcess(false);
          
          window.location.href = `${res?.data?.qrcodE_STRING}&am=${global}`;
        
          // window.open(`${res?.data?.qrcodE_STRING}&am=${global}`);
          // setTimeout(() => {
          //   checkupideposit(order_id, order_token);
          //   setProcess(false);
          // }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.status,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      // })
      // .catch((e) => {
      //   setProcess(false);
      //   console.log(e, "error");
  
      //   if (e.response.status == 400 || e.response.status == 429) {
      //     Swal.fire({
      //       title: e.response?.data.msg,
      //       icon: "warning",
      //       confirmButtonText: "OK",
      //     });
      //   } else {
      //     Swal.fire({
      //       title: "error!! Please refresh and connect to admin",
      //       icon: "warning",
      //       confirmButtonText: "OK",
      //     });
      //   }
      //   alert(e);
      // });
  };

  const ManualPayments = async () => {
    setIsloading1(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${access_token}`);

    var formdata = new FormData();
    formdata.append("Transaction_Screenshot", scrnshot);
    formdata.append("amount", global);
    formdata.append("referenceId", utr);

    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // };

    // fetch(baseUrl + "manual/deposit/txn", requestOptions)

     const result = await apiRequest("manual/deposit/txn","POST",formdata ,myHeaders)
      // .then((response) => response.json())
      // .then((result) => {  
        setIsloading1(false);

        if (result.status === "Pending") {
          Swal.fire({
            title: result.message,
            icon: "success",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: result.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
        history.push("/");
        handleClose_won();
      // })

      // .catch((error) => console.log("error", error));
  };


  // language 
  const translations = {
    hi: {
      addMoney: 'पैसे जोड़ने के लिए राशि चुनें |',
      money: 'राशि डालें  ',
      next: 'आगे बड़े ',
      addAmount: 'राशि जोड़ी जानी है ',
      referPerson: 'रेफ़रल | ',
    },
    en: {
      addMoney: 'Choose Amount To Add',
      money: 'Enter Amount',
      next: 'Next',
      addAmount: 'Amount to be added',
      referPerson: 'Referral',
    },
  };

  const { language } = useContext(LanguageContext);

   // change theme 
    const theme  = localStorage.getItem('theme')


   const styles = {
     backgroundColor: theme === 'light' ? '#fff' : 'black',
     color: theme === 'light' ? 'black' : '#fff',
     // padding: '20px',
     // margin: '20px',
   };
   


  return (
    <>
      <Header user={userAllData} />
      <div className="leftContainer mb_space" style={{...styles, height:"112vh"}}>
        <div className="pt-5 mt-5  Orher_page_main_section">
          {Boolean(!process1) && (
            <div>
              {Boolean(next == 1) && (
                <div className="">
                  <div className="d-flex align-items-center">
                  <h2 className="profile_headings mb-0" style={styles}>{translations[language].addMoney}</h2>
                   
                   <a 
                     href={addCaseSetting?.depositGuide}
                     target="_blank" 
                     rel="noopener noreferrer" // For security, especially with target="_blank"
                     className="youtube_btn ml-auto" // Add your CSS classes here for styling
                   >
                     <p className="mb-0">Deposit कैसे करे</p>
                    
                     <img 
                       src={buttonIcon}
                       alt="YouTube Icon" 
                       width="25px" 
                       className="ml-2" // Apply any necessary margin or styling
                     />
                   </a>
                  </div>
                 
                  <div className="add_amount_main_box mt-4">
                    <div className="add_amount_main_box_inner">
                      <label className="label" style={styles}>{translations[language].money}</label>
                      <div className="enter_amount_input_box ">
                        <span className="img-size">
                        <img
                      src="/nkcoin.png"
                      alt=""
                      className="img-fluid"
                    />
                        </span>
                        <input
                          className="enter_amount_input"
                          type="tel"
                          id="amountInput"
                          value={global}
                          onChange={(e) => {
                            e.target.value > 0
                              ? e.target.value <= 50000
                                ? setGlobal(parseInt(e.target.value))
                                : setGlobal(50000)
                              : e.target.value < 0
                              ? setGlobal(10)
                              : setGlobal(0);
                          }}
                        />
                      </div>
                      {/* <div className="add_amount_min_max">
                        <p>
                          {" "}
                          Min: {minLimit}, Max: {maxLimit}
                        </p>
                        <p className="ml-1">
                          {" "}
                          ** 1 RK{" "}
                         ₹{" "}
                          = 1 rupee
                        </p>
                      </div> */}
                    </div>

                    <div className="add_amount_buttons_main">
                      <button
                        className="profile-bg-4"
                        onClick={() => {
                          setGlobal(100);
                        }}
                      >
                        <span>
                        <img
                      src="/nkcoin.png"
                      alt=""
                      className="img-fluid img-size"
                      style={{marginRight:"2px"}}
                    />
                        </span>
                        100
                      </button>
                      <button
                      className="profile-bg-3"
                        onClick={() => {
                          setGlobal(250);
                        }}
                      >
                         <span>
                        <img
                      src="nkcoin.png"
                      alt=""
                      className="img-fluid img-size"
                      style={{marginRight:"2px"}}

                    />
                        </span>
                        250
                      </button>
                      <button
                        className="profile-bg-2"
                        onClick={() => {
                          setGlobal(500);
                        }}
                      >
                         <span>
                        <img
                      src="/nkcoin.png"
                      alt=""
                      className="img-fluid img-size"
                      style={{marginRight:"2px"}}
                    />
                        </span>
                        500
                      </button>
                      <button
                        className="mt-sm-0 profile-bg-1"
                        onClick={() => {
                          setGlobal(2000);
                        }}
                      >
                       <span >
                        <img
                      src="/nkcoin.png"
                      alt=""
                      className="img-fluid img-size"
                      style={{marginRight:"2px"}}
                    />
                        </span>
                        2000
                      </button>
                    </div>

                    <div className="add_cash_nest_btn">
                      {addCaseSetting.isDeposit ? (
                        <button
                          type="button"
                          id="addcase"
                         className="profile-bg-1"
                          onClick={handleNext}
                        >
                          {translations[language].next} <BsArrowRight className="icons" />
                        </button>
                      ) : (
                        <p className="deposit_service" id="depositClose">
                          Deposit services Close for some time
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {Boolean(next == 2) && (
                <div className="">
                  <>
                    <div className="pb-3">
                      <div className={`${css.games_section}`}>
                        <div className="d-flex position-relative align-items-center justify-content-between">
                          <div className="add_cash_small_haedings">
                           {translations[language].addAmount}
                            ₹
                            <b>{global}</b>
                          </div>
                          <button
                            type="button"
                            onClick={() => setNext(1)}
                            className="samll_btn"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="profile_headings">
                        Pay Through QR CODE
                      </div>

                      {/* PHONE pay  */}
                      {Boolean(isPhonePayActive) && (
                        <div
                          onClick={() => fetchPaymentUrl()}
                          className="add-fund-box mt-3"
                          style={{ paddingTop: "0px", height: "60px" }}
                        >
                          {isLoading ? (
                            <div
                              className="loaderReturn"
                              style={{ zIndex: "99" }}
                            >
                              <img
                                src={"/images/LandingPage_img/loader1.gif"}
                                style={{ width: "100%" }}
                              />
                            </div>
                          ) : (
                            <div
                              className="d-flex align-items-center"
                              style={{
                                backgroundColor: "#fafafa",
                                border: "1px solid #e0e0e0",
                                borderRadius: "7px",
                              }}
                            >
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  height: "60px",
                                  display: "flex",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  width="40px"
                                  src="/UPI.png"
                                  alt=""
                                  style={{
                                    marginLeft: "7px",
                                    paddingBottom: "10px",
                                    paddingLeft: "3px",
                                    paddingTop: "5px",
                                  }}
                                />
                              </div>
                              <div className="d-flex justify-content-center flex-column ml-4">
                                <div className="jss30">
                                  <strong>Pay Now 1</strong>
                                </div>
                                <div className="jss31"></div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                       {/* upigateway */}
                       {Boolean(isUpiGateway) && (
                        <div
                          onClick={() => upigateway()}
                          className="add-fund-box mt-3"
                          style={{ paddingTop: "0px", height: "60px" }}
                        >
                          {isLoading ? (
                            <div
                              className="loaderReturn"
                              style={{ zIndex: "99" }}
                            >
                              <img
                                src={"/images/LandingPage_img/loader1.gif"}
                                style={{ width: "100%" }}
                              />
                            </div>
                          ) : (
                            <div
                              className="d-flex align-items-center"
                              style={{
                                backgroundColor: "#fafafa",
                                border: "1px solid #e0e0e0",
                                borderRadius: "7px",
                              }}
                            >
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  height: "60px",
                                  display: "flex",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  width="40px"
                                  src="/UPI.png"
                                  alt=""
                                  style={{
                                    marginLeft: "7px",
                                    paddingBottom: "10px",
                                    paddingLeft: "3px",
                                    paddingTop: "5px",
                                  }}
                                />
                              </div>
                              <div className="d-flex justify-content-center flex-column ml-4">
                                <div className="jss30">
                                  <strong style={{color:"black"}}>Pay Now</strong>
                                </div>
                                <div className="jss31"></div>
                              </div>
                            </div>
                          )}
                        </div>
                        )}
                      
                      {/*Air pay  */}
                      {global >= manualData?.KvmpayMin && Boolean(isKvmPayActive) &&  global <= manualData?.KvmpayMax ? (
    <div
        onClick={() => kvmpay()}
        className="add-fund-box mt-3"
        style={{ paddingTop: "0px", height: "60px" }}
    >
        {isLoading ? (
            <div className="loaderReturn" style={{ zIndex: "99" }}>
                <img
                    src="/images/LandingPage_img/loader1.gif"
                    style={{ width: "100%" }}
                />
            </div>
        ) : (
            <div
                className="d-flex align-items-center"
                style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #e0e0e0",
                    borderRadius: "7px",
                }}
            >
                <div
                    className="d-flex align-items-center"
                    style={{
                        height: "60px",
                        display: "flex",
                        textAlign: "center",
                    }}
                >
                    <img
                        width="40px"
                        src="/UPI.png"
                        alt="UPI Icon"
                        style={{
                            marginLeft: "7px",
                            paddingBottom: "10px",
                            paddingLeft: "3px",
                            paddingTop: "5px",
                        }}
                    />
                </div>
                <div className="d-flex justify-content-center flex-column ml-4">
                    <div className="jss30">
                        <strong style={{ color: "black" }}>Pay Now</strong>
                    </div>
                    <div className="jss31"></div>
                </div>
            </div>
        )}
    </div>
) : null}


                      {/* HOADAPAY */}
                      {Boolean(isHoadaPaypayInActive) && (
                        <div
                          onClick={() => {
                            method.current = "upipay";
                            Hoada("link", "upi", "upipay", "upigateway");
                            setHoadaPay(true);
                          }}
                          className="add-fund-box mt-3"
                          style={{ paddingTop: "0px", height: "60px" }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              backgroundColor: "#fafafa",
                              border: "1px solid #e0e0e0",
                              borderRadius: "7px",
                            }}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{
                                height: "60px",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <img
                                width="40px"
                                src="UPI.png"
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center flex-column ml-4">
                              <div className="jss30">
                                <strong>PAY NOW 2</strong>
                              </div>
                              <div className="jss31"></div>
                            </div>
                          </div>
                        </div>
                      )}
{/* MYPAY */}

{Boolean(isMyPayActive) && (
                        <div
                          onClick={() =>myPay()}
                          className="add-fund-box mt-3"
                          style={{ paddingTop: "0px", height: "60px" }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              backgroundColor: "#fafafa",
                              border: "1px solid #e0e0e0",
                              borderRadius: "7px",
                            }}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{
                                height: "60px",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <img
                                width="40px"
                                src="UPI.png"
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center flex-column ml-4">
                              <div className="jss30">
                                <strong>PAY NOW 2</strong>
                              </div>
                              <div className="jss31"></div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* pay account  */}
                      {manualData?.isAccountActive  && global >= manualData?.upiMin && global <= manualData?.upiMax ?  (
                          <div
                            onClick={() => {
                              setNext(4);
                            }}
                            // onClick={() => fetchPaymentUrl()}
                            className="add-fund-box mt-3"
                            style={{ paddingTop: "0px", height: "60px" , cursor:"pointer"}}
                          >
                            {isLoading ? (
                              <div
                                className="loaderReturn"
                                style={{ zIndex: "99" }}
                              >
                                <img
                                  src={"/images/LandingPage_img/loader1.gif"}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  backgroundColor: "#fafafa",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "7px",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "60px",
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    width="40px"
                                    src="/Bank.png"
                                    alt=""
                                    style={{
                                      marginLeft: "7px",
                                      paddingBottom: "10px",
                                      paddingLeft: "3px",
                                      paddingTop: "5px",
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column ml-4">
                                  <div className="jss30">
                                    <strong style={{color:"black"}}>PAY WITH ACCOUNT</strong>
                                  </div>
                                  <div className="jss31"></div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      <div className="Qrcode">
                        {/* Qr code 1 */}
             
                        {manualData?.isPayNowOne  && global >= manualData?.upiMin && global <= manualData?.upiMax ?  (
                          <div
                            onClick={() => {
                              setNext(3);
                              getImageUrl(PayOneImage, PayNow1Url);
                            }}
                            // onClick={() => fetchPaymentUrl()}
                            className="add-fund-box mt-3"
                            style={{ paddingTop: "0px", height: "60px" , cursor:"pointer"}}
                          >
                            {isLoading ? (
                              <div
                                className="loaderReturn"
                                style={{ zIndex: "99" }}
                              >
                                <img
                                  src={"/images/LandingPage_img/loader1.gif"}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  backgroundColor: "#fafafa",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "7px",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "60px",
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    width="40px"
                                    src="/UPI.png"
                                    alt=""
                                    style={{
                                      marginLeft: "7px",
                                      paddingBottom: "10px",
                                      paddingLeft: "3px",
                                      paddingTop: "5px",
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column ml-4">
                                  <div className="jss30">
                                    <strong style={{color:"black"}}>QR CODE 1</strong>
                                  </div>
                                  <div className="jss31"></div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {/* Qr code 2 */}
                        {manualData?.isPayNowTwo ? (
                          <div
                            onClick={() => {
                              setNext(3);
                              getImageUrl(PayTwoImage, PayNow2Url);
                            }}
                            // onClick={() => fetchPaymentUrl()}
                            className="add-fund-box mt-3"
                            style={{ paddingTop: "0px", height: "60px" }}
                          >
                            {isLoading ? (
                              <div
                                className="loaderReturn"
                                style={{ zIndex: "99" }}
                              >
                                <img
                                  src={"/images/LandingPage_img/loader1.gif"}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  backgroundColor: "#fafafa",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "7px",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "60px",
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    width="40px"
                                    src="/UPI.png"
                                    alt=""
                                    style={{
                                      marginLeft: "7px",
                                      paddingBottom: "10px",
                                      paddingLeft: "3px",
                                      paddingTop: "5px",
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column ml-4">
                                  <div className="jss30">
                                    <strong>QR CODE 2</strong>
                                  </div>
                                  <div className="jss31"></div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {/* Qr code 3 */}
                        {manualData?.isPayNowThree ? (
                          <div
                            onClick={() => {
                              setNext(3);
                              getImageUrl(PayThreeImage, PayNow3Url);
                            }}
                            // onClick={() => fetchPaymentUrl()}
                            className="add-fund-box mt-3"
                            style={{ paddingTop: "0px", height: "60px" }}
                          >
                            {isLoading ? (
                              <div
                                className="loaderReturn"
                                style={{ zIndex: "99" }}
                              >
                                <img
                                  src={"/images/LandingPage_img/loader1.gif"}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  backgroundColor: "#fafafa",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "7px",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "60px",
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    width="40px"
                                    src="/UPI.png"
                                    alt=""
                                    style={{
                                      marginLeft: "7px",
                                      paddingBottom: "10px",
                                      paddingLeft: "3px",
                                      paddingTop: "5px",
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column ml-4">
                                  <div className="jss30">
                                    <strong>QR CODE 3</strong>
                                  </div>
                                  <div className="jss31"></div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {/* Qr code 4 */}
                        {manualData?.isPayNowFour ? (
                          <div
                            onClick={() => {
                              setNext(3);
                              getImageUrl(PayFourImage, PayNow4Url);
                            }}
                            // onClick={() => fetchPaymentUrl()}
                            className="add-fund-box mt-3"
                            style={{ paddingTop: "0px", height: "60px" }}
                          >
                            {isLoading ? (
                              <div
                                className="loaderReturn"
                                style={{ zIndex: "99" }}
                              >
                                <img
                                  src={"/images/LandingPage_img/loader1.gif"}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  backgroundColor: "#fafafa",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "7px",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "60px",
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    width="40px"
                                    src="/UPI.png"
                                    alt=""
                                    style={{
                                      marginLeft: "7px",
                                      paddingBottom: "10px",
                                      paddingLeft: "3px",
                                      paddingTop: "5px",
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column ml-4">
                                  <div className="jss30">
                                    <strong>QR CODE 4</strong>
                                  </div>
                                  <div className="jss31"></div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {/* Qr code 5 */}
                        {manualData?.isPayNowFive ? (
                          <div
                            onClick={() => {
                              setNext(3);
                              getImageUrl(PayFiveImage, PayNow5Url);
                            }}
                            className="add-fund-box mt-3"
                            style={{ paddingTop: "0px", height: "60px" }}
                          >
                            {isLoading ? (
                              <div
                                className="loaderReturn"
                                style={{ zIndex: "99" }}
                              >
                                <img
                                  src={"/images/LandingPage_img/loader1.gif"}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  backgroundColor: "#fafafa",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "7px",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "60px",
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    width="40px"
                                    src="/UPI.png"
                                    alt=""
                                    style={{
                                      marginLeft: "7px",
                                      paddingBottom: "10px",
                                      paddingLeft: "3px",
                                      paddingTop: "5px",
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column ml-4">
                                  <div className="jss30">
                                    <strong>QR CODE 5</strong>
                                  </div>
                                  <div className="jss31"></div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                </div>
              )}

              {Boolean(next == 3) && (
                <>
                  <div class="alrt_msg_div">
                    <h5 class="">
                    अगर कोई यूजर मैन्युअल डिपॉजिट अमाउंट जितना पे करता है उतना ही पेमेंट डालकर UTR नंबर और स्क्रीनशॉट सबमिट करें अगर आप पेमेंट कम या और ज्यादा डालते हो आपका किया हुआ डिपॉजिट ऐड नहीं होगा |
                    </h5>
                  </div>
                  <img
                    src={baseUrl + `${ImageUrl}`} // Replace with the actual path to your image file
                    alt="QR Image"
                    style={{ width: "90%", display: "block", margin: "auto" }}
                  />
                  {/* <div>
                    <DownloadLink
                      className="results_btn results_btn_cancel mt-3"
                      style={{ width: "50%" }}
                      label=" Download QR"
                      filename={baseUrl + `${ImageUrl}`}
                      exportFile={() => "My cached data"}
                    />
                  </div> */}

                  <div className="Refer_code">
                    <h3 style={styles}>{PayNow1Url}</h3>
                    <button onClick={(e) => copyCode(e)}>Copy</button>
                  </div>

                  <div>
                    <button
                      className="results_btn results_btn_win mt-2"
                      onClick={handleShow_won}
                    >
                      {" "}
                      Upload Payment Details
                    </button>
                  </div>
                </>
              )}
              {Boolean(next == 4) && (
                <>
                  <div class="alrt_msg_div" >
                    <h5 class="">
                    अगर कोई यूजर मैन्युअल डिपॉजिट अमाउंट जितना पे करता है उतना ही पेमेंट डालकर UTR नंबर और स्क्रीनशॉट सबमिट करें अगर आप पेमेंट कम या और ज्यादा डालते हो आपका किया हुआ डिपॉजिट ऐड नहीं होगा |
                    </h5>
                  </div>
                  <div class="alrt_msg_div" style={{border:"2px solid red"}}>
                  <div className="Refer_code" style={{paddingTop:"5px"}}>
                    <p style={{...styles, fontSize:"20px"}}> 
                    Account Number :  <span style={{color:'red'}} className="mb-4">
                       {manualData?.acNo}
                        </span></p>
                    <button onClick={(e) => copyCode1(e)}>Copy</button>
                  </div>
                 
                  </div>
                  <div class="alrt_msg_div" style={{border:"2px solid red"}}>
                  <div className="Refer_code" style={{paddingTop:"5px"}}>
                    <p style={{...styles, fontSize:"20px"}}> 
                    Beneficiary Name :  <span style={{color:'red'}} className="mb-4">
                       {manualData?.acName}
                        </span></p>
                    <button onClick={(e) => copyCode2(e)}>Copy</button>
                  </div>
                 
                  </div>
                  <div class="alrt_msg_div" style={{border:"2px solid red"}}>
                  <div className="Refer_code" style={{paddingTop:"5px"}}>
                    <p style={{...styles, fontSize:"20px"}}> 
                    IFSC Code :  <span style={{color:'red'}} className="mb-4">
                    {manualData?.ifsccode}
                        </span></p>
                    <button onClick={(e) => copyCode3(e)}>Copy</button>
                  </div>
                 
                  </div>

<div>
  <p>
 NOTE :- पेमेंट सक्सेस होने के बाद निचे दिय गए बटन पर क्लिक करके स्क्रीनशॉट और UTR नंबर डालके सबमिट कर दे | 
  </p>
</div>

                  <div>
                    <button
                      className="results_btn results_btn_win mt-2"
                      onClick={handleShow_won}
                    >
                      {" "}
                      Upload Payment Details
                    </button>
                  </div>
                </>
              )}
            </div>
          )}

          {Boolean(process1) && (
            <div className="loaderReturn" style={{ zIndex: "99" }}>
              <img
                src={"/images/LandingPage_img/loader1.gif"}
                style={{ width: "100%" }}
              />
            </div>
          )}
        </div>
      </div>

      <section className="win_modal">
        <Modal
          show={show_won}
          onHide={handleClose_won}
          className="cancel_popup_reason_popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <h3>Payment Details</h3>
              <div>
                <lable className="commaon_label">AMOUNT</lable>
                <input
                  className="commaon_input_box mb-2"
                  type="number"
                  required
                  value={global}
                  onChange={(e) => setGlobal(e.target.value)}
                  placeholder="Enter amount"
                  readOnly
                />
                <lable className="commaon_label mt-4">UTR NUMBER</lable>
                <input
                  className="commaon_input_box"
                  type="text"
                  required
                  value={utr}
                  onChange={(e) => setutr(e.target.value)}
                  placeholder="Enter UTR Number"
                />
              </div>

              <div className={`${css1.doc_upload} mt-4 upload_ss_btn`}>
                <input
                  type="file"
                  onChange={handleChange}
                  accept="image/*"
                  required
                />
                {!scrnshot && (
                  <div className="cxy flex-column position-absolute ">
                    <i
                      className="fa-solid fa-arrow-up"
                      style={{ color: "#fff" }}
                    ></i>
                    <div
                      className={`${css1.sideNav_text} mt-2 upload_ss_btn_name`}
                    >
                      Upload screenshot.
                    </div>
                  </div>
                )}
                {scrnshot && (
                  <div className={css1.uploaded}>
                    <img
                      src="/images/file-icon.png"
                      width="26px"
                      alt=""
                      style={{ marginRight: "20px" }}
                    />
                    <div
                      className="d-flex flex-column"
                      style={{ width: "80%" }}
                    >
                      <div
                        className={`${css1.name} `}
                        style={{ color: "#fff" }}
                      >
                        {scrnshot.name}
                      </div>
                      <div className={css1.size}>
                        {(scrnshot.size / 1024 / 1024).toFixed(2)} MB
                      </div>
                    </div>
                    <div className="image-block">
                      <img
                        src="/images/global-cross.png"
                        width="10px"
                        alt=""
                        onClick={() => {
                          clearImage();
                          handleClose_won();
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div style={{ width: "100%", marginTop: "20px" }}>
                <img
                  src={scrnshot1}
                  style={{ width: "100%" }}
                  className="screenshot_img"
                  required
                />
              </div>

              {/* {fecthStatus == null && fecthStatus == undefined && (
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-danger mt-3 text-white results_btn w-100"
                  id="post"
                  // onClick={(e) => {
                  //   Result(e);
                  // }}
                  onClick={ManualPayments}
                  disabled={!scrnshot}
                />
              )} */}

              {fecthStatus == null && fecthStatus == undefined && (
                <>
                  {isLoading1 ? (
                    <button className="Login-button cxy" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </button>
                  ) : (
                    <input
                      type="submit"
                      value="SUBMIT"
                      className="btn btn-danger mt-3 text-white results_btn w-100"
                      id="post"
                      onClick={ManualPayments}
                      disabled={!utr}
                      style={{fontSize:"20px !important"}}
                    />
                  )}
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </section>

      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </>
  );
};

export default Addcase;
