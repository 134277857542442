import React, { useEffect, useState, useRef, useContext } from "react";
import "../css/viewGame1.css";
import "../css/layout.css";
import axios from "axios";
import {
  useHistory,
  useLocation,
  NavLink,
  a,
  useNavigate,
} from "react-router-dom";
import Header from "../Components/Header";
import css from "../css/Pan.module.css";
import Rightcontainer from "../Components/Rightcontainer";
import Swal from "sweetalert2";
import "../css/Loader.css";
import io from "../Components/socket";
import Compressor from "compressorjs";
import socket from "../Components/socket";
import { Chip } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";
import WarningSound from "../uiComponents/audio1.mp3";
import WarningSound2 from "../uiComponents/audios.mp3";
import Snake1 from "../uiComponents/snake1.mp3";
import Snake2 from "../uiComponents/snake2.mp3";
import Spinner from "react-bootstrap/Spinner";

import "bootstrap/dist/css/bootstrap.min.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LanguageContext } from "../Components/LanguageContext";
import { useTheme } from "../../ThemeContext";
import { apiRequest } from "../../apiService/allApi";
// import {useNavigate} from "react-router-dom"

export default function ViewGame1(props) {
  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  // +++++++++++++++++++++++++++++

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show_won, setShow_won] = useState(false);
  const handleClose_won = () => setShow_won(false);

  const handleShow_won = () => setShow_won(true);

  const [show_lost, setShow_lost] = useState(false);
  const handleClose_lost = () => setShow_lost(false);

  const handleShow_lost = () => setShow_lost(true);
  const [isChecked, setIsChecked] = useState(false);

  const history = useHistory();

  const location = useLocation();
  const path = location.pathname.split("/")[2];

  const [Game, setGame] = useState("0");
  // console.log(Game);
  const [status, setStatus] = useState(null);

  const [fecthStatus, setFecthStatus] = useState();
  const [scrnshot, setScrnshot] = useState(null);
  const [scrnshot1, setScrnshot1] = useState(""); // ADDED BY TEAM
  const [initialRoom, setInitialRoom] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [isLoading1, setIsloading1] = useState(false);

  const addsetting = localStorage.getItem("sitSetting");
  const addCaseSetting = JSON.parse(addsetting);

  const [reason, setReason] = useState(null);

  const [roomcode, setRoomcode] = useState();
  let submitReq = useRef(false);
  const isMounted = useRef(true);

  const [submitProcess, setProcess] = useState(true);
  const handleChangeCheckbox = () => {
    setIsChecked(!isChecked);
  };

  /// user details start
  const [user, setUser] = useState();
  const [userAllData, setUserAllData] = useState();

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    Allgames(userId);
    setUser(userId);
    // setUserAllData(res.data);
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      history.push("/login");
    }

    // role();
  }, []);
  useEffect(() => {
    let access_token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    Allgames(userId);
    setUser(userId);
    // setUserAllData(res.data);
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      history.push("/login");
    }

    // role();
  }, [props.userData]);

  useEffect(() => {
    io.on("updateroomcode", (data) => {
      // Allgames(userId);
      // setStopCountdown(true);
      let userId = localStorage.getItem("userId");

      if (data?.Accepetd_By?._id == userId) {
        setInitialRoom(data);
        setGame(data);
      }
    });
  }, [initialRoom.Room_code == 0]);

  const handleReason1 = () => {
    setStatus("cancelled");
    setReason("No Room code");
  };
  const handleReason2 = () => {
    setReason("Not joined");
    setStatus("cancelled");
  };
  const handleReason3 = () => {
    setReason("Not Playing");
    setStatus("cancelled");
  };
  const handleReason4 = () => {
    setReason("Dont want to play");
    setStatus("cancelled");
  };
  const handleReason5 = () => {
    setReason("Opponent Abusing");
    setStatus("cancelled");
  };
  const handleReason6 = () => {
    setReason("Game not Started");
    setStatus("cancelled");
  };
  const handleReasoneChange = (e) => {
    setReason(e.target.value);
  };

  const getRoomCode = async () => {
    setIsloading1(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    // await axios
    //   .patch(
    //     baseUrl + `challange/roomcode/${path}`,
    //     {
    //       Room_code: roomcode,
    //     },
    //     { headers }
    //   )
     
      const res = await apiRequest( `challange/roomcode/${path}`,"PATCH",{ Room_code: roomcode,},headers)
    

      // .then((res) => {
        setIsloading1(false);
        if (res.data?.status == false) {
          Swal.fire({
            title: res.data.msg,
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          setIsloading1(false);
          setGame(res.data);
        }
      // })
      // .catch((e) => {
      //   if (e.response.status == 401) {
      //     localStorage.removeItem("token");
      //     localStorage.removeItem("token");
      //     window.location.reload();
      //     history.push("/login");
      //   }
      // });
  };

  /// user details end
  const [ALL, setALL] = useState();

  const Allgames = async (userId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    // await axios
    //   .get(baseUrl + `challange/${path}`, { headers })

      const res = await apiRequest( `challange/${path}`,"GET",null,headers)
      console.log(res.data , 'k');
      // .then((res) => {
        if (res.data?.status == false) {
          Swal.fire({
            title: res.data.msg,
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        if (res.data?.Status == "new" || res.data.Status == "requested") {
          setTimeout(async () => {
            // await axios
            //   .get(baseUrl + `challange/${path}`, {
            //     headers,
            //   })

              const res = await apiRequest(`challange/${path}`,"GET",null,headers)
              // .then((res) => {
                if (
                  res.data.Status == "new" ||
                  res.data.Status == "requested"
                ) {
                  history.push(props?.location?.state?.prevPath);
                } else {
                  setProcess(false);
                }
              // })
              // .catch((error) => {
              //   console.error(error);
              //   history.push(props?.location?.state?.prevPath);
              // });
          }, 10000);
        } else {
          setProcess(false);
        }
        setALL(res.data);
        setGame(res.data);

        if (userId == res.data.Accepetd_By._id) {
          setFecthStatus(res.data.Acceptor_status);
        }

        if (userId == res.data.Created_by._id) {
          setFecthStatus(res.data.Creator_Status);
        }
      // })
      // .catch((e) => {
      //   if (e.response?.status == 401) {
      //     localStorage.removeItem("token");
      //     localStorage.removeItem("token");
      //     window.location.reload();
      //     history.push("/login");
      //   }
      // });
  };
  // -----Start timer ------------------------------------------------------------------------
  const [showDiv, setShowDiv] = useState(true);
  const [secondsRemaining, setSecondsRemaining] = useState(null);

  // Replace this with the actual timestamp from MongoDB
  const createdAtTimestamp = new Date(Game.createdAt).getTime(); // Example timestamp

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const currentTime = new Date().getTime();
      const differenceInSeconds = Math.floor(
        (currentTime - createdAtTimestamp) / 1000
      );
      //  console.log(differenceInSeconds,showDiv)
      if (differenceInSeconds >= 240) {
        setShowDiv(false);
      } else {
        setSecondsRemaining(240 - differenceInSeconds);
      }
    };

    calculateTimeRemaining(); // Calculate initial time remaining

    // Update time remaining every second
    const timer = setInterval(() => {
      calculateTimeRemaining();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [createdAtTimestamp]);

  // -----end timer------

  const clearImage = (e) => {
    setScrnshot1(null);
    setScrnshot(null);
    setStatus(null);
  };
  // Result
  const Suggestion = (e) => {
    alert("ok");
  };
  const Result = async (e) => {
    e.preventDefault();

    if (submitReq.current == false) {
      submitReq.current = true;
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      if (status) {
        setProcess(true);
        const formData = new FormData();
        if (scrnshot != null) {
          formData.append("file", scrnshot);
        }
        formData.append("status", status);
        if (status == "cancelled") {
          formData.append("reason", reason);
        }

        // await axios({
        //   method: "post",
        //   url: baseUrl + `challange/result/${path}`,
        //   data: formData,
        //   headers: headers,
        // })

        const res = await apiRequest(`challange/result/${path}`,"POST",formData,headers)
          // .then((res) => {
            // console.log(res,'res')
            if (res.data?.status == false) {
              submitReq.current = false;
              setProcess(false);
              Swal.fire({
                position: "center",
                icon: "error",
                type: "error",
                title: res.data.msg,
                showConfirmButton: true,
              });
            } else {
              io.emit("resultAPIdata", path);
              // console.log(path, "id")
              submitReq.current = false;
              setProcess(false);
              history.push(props?.location?.state?.prevPath);
            }
          // })
          // .catch((e) => {
          //   console.log(e, "e error msg q");
          //   Swal.fire({
          //     icon: "error",
          //     title: "Oops...",
          //     text: e.message,
          //   });
          //   {
          //     console.log(e.message);
          //   }
          //   if (e.response?.status == 401) {
          //     localStorage.removeItem("token");
          //     localStorage?.removeItem("token");
          //     window?.location?.reload();
          //     history.push("/login");
          //   }
          // });
      } else {
        submitReq.current = false;
        alert("please fill all field or Re-Select result status");
      }
    }
  };

  const copyCode = (e) => {
    navigator.clipboard.writeText(Game.Room_code);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Room Code Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };
  const Completionist = () => <span>You are good to go!</span>;

  // ADDED BY TEAM
  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]));
    const image = e.target.files[0];
    if (image && image.size / 1000 > 300) {
      console.log(" compression");
      new Compressor(image, {
        quality: 0.6,
        success(compressedResult) {
          if (compressedResult) {
            setScrnshot(compressedResult);
          } else {
            setScrnshot(image);
          }
        },
      });
    } else {
      setScrnshot(e.target.files[0]);
    }
  };
  // cancel button active function
  $(document).ready(function () {
    $(".cancel_reason_btns_bg").click(function () {
      $(".cancel_reason_btns_bg").removeClass("nav_active");
      $(this).addClass("nav_active");
    });
  });

  const handleSetRoomCode = () => {
    const inputValue = roomcode;
    if (Game.Game_type !== "LudoSnake") {
      if (
        /^[0-9]+$/.test(inputValue) &&
        inputValue.length === 8 &&
        inputValue[0] === "0"
      ) {
        getRoomCode();
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please enter a valid 8-digit code that starts with 0.",
          showConfirmButton: true,
        });
      }
    } else {
      getRoomCode();
    }
  };

  function winnAmount(gameAmount) {
    let profit = null;
    // {console.log(addCaseSetting?.commissionRange2)}
    if (gameAmount >= 50 && gameAmount <= 250)
      profit = (gameAmount * addCaseSetting?.commissionRange1) / 100;
    else if (gameAmount > 250 && gameAmount <= 500)
      profit = addCaseSetting?.commissionRange2;
    else if (gameAmount > 500)
      profit = (gameAmount * addCaseSetting?.commissionRange3) / 100;
    return gameAmount - profit;
  }

  // language
  const translations = {
    hi: {
      back: "पीछे जाये",
      code: "कोड कॉपी करे",
      roomcode: "रूम कोड सेट करें",
      rule: "खेल के नियम",
      status: "खेल का परिणाम ",
      won: "मैं जीता",
      loss: "में हारा ",
      cancel: "रद्द ",
    },
    en: {
      back: "Back",
      code: "Copy Code",
      roomcode: " Set Room Code",
      rule: "game rule",
      status: "Match Status",
      won: "i Won",
      loss: "i Lost",
      cancel: "Cancel ",
    },
  };

  const { language } = useContext(LanguageContext);

  // change theme
  const theme = localStorage.getItem("theme");
  const styles = {
    backgroundColor: theme === "light" ? "#fff" : "black",
    color: theme === "light" ? "black" : "#fff",
  };
  const styles1 = {
    backgroundColor: theme === "light" ? "#27241b" : "#27241b",
    color: theme === "light" ? "black" : "#fff",
  };

  const styles2 = {
    backgroundColor: theme === "light" ? "#facc06" : "#27241b",
    color: theme === "light" ? "black" : "#fff",
  };

  return (
    <>
      {Game && (
        <div className="leftContainer mb_space" style={styles}>
          <div
            className="pt-5 mt-5   Orher_page_main_section"
          >
            {Game.Game_type === "ludoClassicManual" ? (
              <div className="alrt_msg_div" style={styles2}>
                <h5 className="">
                  {JSON.parse(localStorage.getItem("sitSetting")) == "undefined"
                    ? ""
                    : JSON.parse(localStorage.getItem("sitSetting"))
                        ?.ludoClassicManualBattleMsg}
                </h5>
              </div>
            ) : (
              <div className="alrt_msg_div">
                <h5 className="">
                  {JSON.parse(localStorage.getItem("sitSetting")) == "undefined"
                    ? ""
                    : JSON.parse(localStorage.getItem("sitSetting"))
                        ?.ludoClassicAutobattleMsg}
                </h5>
              </div>
            )}

            <div className="main-area" style={styles}>
              <div className="viewgame_topbtn" style={styles}>
                <div>
                  <span
                    className={`${css.gamesSectionHeadline} text-uppercase font-weight-bold rule_view text-dark`}
                  >
                    <NavLink to="/landing" className="text-success">
                      <ArrowBackIcon />
                      {translations[language].back}
                    </NavLink>
                  </span>
                </div>

                <div className="">
                  {/* <span
                    className={`${css.gamesSectionHeadline} text-uppercase font-weight-bold rule_view text-success`}
                  >
                    Rules
                    <NavLink to="/Rules">
                      <img
                        className="ml-2"
                        src={
                          process.env.PUBLIC_URL + "/images/Homepage/info.png"
                        }
                        alt=""
                        width="16px"
                      />
                    </NavLink>
                  </span> */}
                </div>
              </div>
              {!Boolean(submitProcess) && (
                <div className="battleCard-bg " style={styles}>
                  <div className="battleCard ">
                    <div
                      className="battle_card_main  mt-0 position-relative"
                      style={{ background: "#2b3453", color: "#fff" }}
                    >
                      <div className="players cxy pt-2 ">
                        <div className="flex-column cxy">
                          <h5 className="headings">
                            {Game.Created_by && Game.Created_by.Name}
                          </h5>
                          {Game?.Created_by?.avatar ? (
                            <img
                              src={baseUrl + `${Game.Created_by.avatar}`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = baseUrl + "user.png";
                              }}
                              width="50px"
                              height="50px"
                              alt="a"
                              style={{
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            <img
                              src="/images/avatars/Avatar1.png"
                              width="30px"
                              height="30px"
                              alt="user"
                              style={{ borderRadius: "50%" }}
                            />
                          )}
                        </div>
                        <img
                          className="mx-3 img-bet"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/Homepage/versus.png"
                          }
                          width="60px"
                          alt=""
                        />
                        <div className="flex-column cxy ">
                          <h5 className="headings">
                            {" "}
                            {Game?.Accepetd_By && Game?.Accepetd_By.Name}
                          </h5>
                          {Game?.Accepetd_By?.avatar ? (
                            <img
                              src={baseUrl + `${Game.Accepetd_By.avatar}`}
                              width="30px"
                              height="30px"
                              alt=""
                              style={{
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            <img
                              src="/images/avatars/Avatar2.png"
                              width="30px"
                              height="30px"
                              alt=""
                              style={{
                                borderRadius: "50%",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between px-3">
                        <div>
                          <p class="d-block mb-0">Entry Fee</p>
                          <div className="amount cxy mt-1">
                            {/* <span style={{ opacity: "0.8" }}>Playing for</span> */}
                            <img
                              src="/nkcoin.png"
                              style={{ width: "9%" }}
                              alt=""
                            />

                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: 700,
                                opacity: "0.8",
                              }}
                            >
                              {Game.Game_Ammount}
                            </span>
                          </div>
                        </div>
                        <div>
                          <p class="d-block mb-0 text-right">Winning Prize</p>
                          <div className="amount cxy mt-1 justify-content-end">
                            {/* <span style={{ opacity: "0.8" }}>Playing for</span> */}
                            <img
                              src="/nkcoin.png"
                              style={{ width: "9%" }}
                              alt=""
                            />

                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: 700,
                                opacity: "0.8",
                              }}
                            >
                              {Game.Game_Ammount +
                                winnAmount(Game?.Game_Ammount)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className='thin-divider-x ' /> */}
                    <div className="battle_card_main border-0" style={styles1}>
                      {(Game.Room_code == null && (
                        <div className=" cxy flex-column">
                          <h6 className="headings">
                            {" "}
                            Waiting for Room Code...{" "}
                          </h6>
                          <h6 className="headings">रूम कोड का इंतजार है।</h6>
                          <div className="lds-spinner">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      )) ||
                        (Game.Room_code != "0" && (
                          <div className="cxy justify-content-around">
                            <div className="">
                              <h2
                                className="profile_headings mb-0 mr-3"
                                style={{color:"#fff"}}
                              >
                                Room Code <span>{Game.Room_code}</span>
                              </h2>
                            </div>
                            <button
                              className="playButton position-static mt-0"
                              onClick={(e) => copyCode(e)}
                              style={{ backgroundColor: "#ffcc00" }}
                            >
                              {translations[language].code}
                            </button>
                          </div>
                        )) ||
                        (Game.Room_code == "0" &&
                          ((Game.Created_by._id == user &&
                            Game.Room_code == "0" && (
                              <div className=" cxy flex-column">
                                {/* {Game.Game_type === "ludoClassicManual" ||
                                Game.Game_type === "ludoClassicRich" ? (
                                  <audio src={WarningSound} autoPlay></audio>
                                ) : (
                                  <audio src={Snake2} autoPlay></audio>
                                )} */}

                                <>
                                  {showDiv ? (
                                    <div className=" cxy flex-column">
                                      {/* <h6 className="headings ">
                                        {" "}
                                        {translations[language].roomcode}
                                      </h6> */}
                                      <h6 className="headings room-heading pt-4 ">
                                        <b>लूडो किंग से रूम कोड अपलोड करें</b>
                                      </h6>
                                      <div
                                        className="pt-1 text-primary"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "20px",
                                        }}
                                      >
                                        {showDiv ? (
                                          <div>
                                            <p>
                                              {/* Remaining Time :{" "} */}
                                              <span
                                                style={{ fontSize: "25px" }}
                                              >
                                                {" "}
                                                {secondsRemaining}{" "}
                                              </span>
                                              seconds
                                            </p>
                                          </div>
                                        ) : (
                                          <div>
                                            <p style={{ color: "red" }}>
                                              Your Time is Complete please Reset
                                              bettel !
                                            </p>
                                          </div>
                                        )}
                                      </div>

                                      <label
                                        className="d-block mr-auto"
                                        htmlFor=""
                                      >
                                        <b>Enter Room Code</b>{" "}
                                      </label>
                                      <div class="user_reder_code_box room-input mt-2">
                                        {" "}
                                        <input
                                          class="commaon_input_box"
                                          type="number"
                                          placeholder="Room Code"
                                          value={roomcode}
                                          onChange={(e) =>
                                            setRoomcode(e.target.value)
                                          }
                                        />
                                        <div class="set  check_btn">
                                          {" "}
                                          {isLoading1 ? (
                                             <button
                                             className="samll_btn_game samll_btn"
                                             disabled
                                             style={{ height: "35px" }}
                                           >
                                             <Spinner
                                               as="span"
                                               animation="grow"
                                               size="sm"
                                               role="status"
                                               aria-hidden="true"
                                             />
                                             Loading...
                                           </button>
                                            ) : (
                                          <button class="samll_btn_game samll_btn" onClick={handleSetRoomCode}>
                                            Set
                                          </button>
                                           )}
                                        </div>
                                      </div>
                                      {/* <input
                                        type="number"
                                        className="form-control mt-1 w-100"
                                        style={{
                                          backgroundColor: "#e8eeee",
                                          border: "1px solid green",
                                        }}
                                        value={roomcode}
                                        onChange={(e) =>
                                          setRoomcode(e.target.value)
                                        }
                                      />
                                      {isLoading1 ? (
                                        <button
                                          className="Login-button cxy mt-2"
                                          disabled
                                          style={{ height: "35px" }}
                                        >
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                          Loading...
                                        </button>
                                      ) : (
                                        <button
                                          className="setroomcode playButton position-static mt-2"
                                          type="button"
                                          onClick={handleSetRoomCode}
                                        >
                                          SET ROOM CODE
                                        </button>
                                      )} */}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>

                                <div className="rules">
                                  <ol className="list-group list-group-numbered">
                                    <li
                                      className="list-group-item"
                                      style={{
                                        color: "#fff",
                                        fontSize: "20px !important",
                                      }}
                                    >
                                      <marquee>
                                        कृपया लूडो किंग से रुम कोड निकल कर SET
                                        ROOM CODE मे डाल दे 
                                      </marquee>
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            )) ||
                            (Game.Accepetd_By._id == user &&
                              Game?.Room_code == "0" && (
                                <div className="roomCode cxy flex-column">
                                  <>
                                    {showDiv ? (
                                      <>
                                        {/* {Game.Game_type ===
                                          "ludoClassicManual" ||
                                        Game.Game_type === "ludoClassicRich" ? (
                                          <audio
                                            src={WarningSound2}
                                            autoPlay
                                          ></audio>
                                        ) : (
                                          <audio src={Snake1} autoPlay></audio>
                                        )} */}
                                        <div
                                          style={{
                                            textAlign: "center",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <h6 className="headings">
                                            {" "}
                                            Waiting for Room Code{" "}
                                          </h6>
                                          <h6 className="headings">
                                            रूम कोड का इंतजार है। [
                                            {secondsRemaining}]
                                          </h6>

                                          <div className="lds-spinner">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div>
                                        <p
                                          style={{
                                            color: "#fff",
                                            fontSize: "20px",
                                          }}
                                        >
                                          Waiting For Room Code
                                        </p>
                                      </div>
                                    )}
                                  </>

                                  <div className="rules">
                                    <ol className="list-group list-group-numbered">
                                      <li
                                        className="list-group-item"
                                        style={{
                                          color: "#fff",
                                          fontSize: "20px !important",
                                        }}
                                      >
                                        <marquee>
                                          {" "}
                                          कृपया इंतजार करे सामने वाला यूजर लूडो
                                          किंग से रूम कोड निकल कर दे रहा  है{" "}
                                        </marquee>
                                      </li>
                                    </ol>
                                  </div>
                                </div>
                              ))))}
                    </div>
                    <div
                      style={{
                        border: "1px solid black",
                        background: "#fff",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        className="match-status-border profile_headings"
                        style={{
                          background: "transparent",
                          color: "#0db25b",
                          fontSize: "25px",
                          marginBottom: "10px",
                        }}
                      >
                        {translations[language].status}
                      </div>
                      <form
                        className="result-area"
                        onSubmit={(e) => {
                          Result(e);
                        }}
                        encType="multipart/form-data"
                      >
                        {fecthStatus !== null && fecthStatus !== undefined && (
                          <p style={{ color: "red" }}>
                            You have already updated your battle result for{" "}
                            <h6 className="d-inline-block text-uppercase">
                              <b>{fecthStatus}</b>
                            </h6>
                          </p>
                        )}
                        {fecthStatus === null && (
                          <>
                            {" "}
                            <p className="pp mt-2">
                              After completion of your game, select the status
                              of the game and post your screenshot below.
                            </p>
                            <div
                              className=" radios result_buttons_group"
                              role="radiogroup"
                              aria-label="Result"
                            >
                              <label className="MuiFormControlLabel-root hidden Mui-disabled">
                                <span
                                  className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary jss2 Mui-checked jss3 Mui-disabled MuiIconButton-colorSecondary Mui-disabled Mui-disabled"
                                  tabIndex={-1}
                                  aria-disabled="true"
                                >
                                  <span className="MuiIconButton-label">
                                    <input
                                      className="jss4 mr-2"
                                      name="battleResult"
                                      type="radio"
                                      defaultValue="select"
                                      defaultChecked
                                      style={{ transform: "scale(1.5)" }}
                                    />
                                  </span>
                                </span>
                                <span
                                  className="MuiTypography-root MuiFormControlLabel-label Mui-disabled MuiTypography-body1"
                                  style={{ marginTop: "3px" }}
                                >
                                  (Disabled option)
                                </span>
                              </label>

                              <label className="MuiFormControlLabel-root results_btn results_btn_win">
                                <span
                                  className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root jss8"
                                  aria-disabled="false"
                                >
                                  <span className="MuiIconButton-label">
                                    <input
                                      className="jss4 mr-2"
                                      name="battleResult"
                                      type="radio"
                                      defaultValue="winn"
                                      onClick={() => {
                                        setStatus("winn");
                                        handleShow_won();
                                      }}
                                      style={{ transform: "scale(1.5)" }}
                                    />
                                  </span>
                                  <span className="MuiTouchRipple-root" />
                                </span>
                                <span
                                  className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                                  style={{ marginTop: "3px" }}
                                >
                                  {translations[language].won}
                                </span>
                              </label>
                              <label className="MuiFormControlLabel-root results_btn results_btn_lose">
                                <span
                                  className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary MuiIconButton-colorSecondary"
                                  aria-disabled="false"
                                  root="[object Object]"
                                >
                                  <span className="MuiIconButton-label">
                                    <input
                                      className="jss4 mr-2"
                                      name="battleResult"
                                      type="radio"
                                      defaultValue="lose"
                                      onClick={() => {
                                        setStatus("lose");

                                        handleShow_lost();
                                      }}
                                      style={{ transform: "scale(1.5)" }}
                                    />
                                  </span>
                                  <span className="MuiTouchRipple-root" />
                                </span>
                                <span
                                  className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                                  style={{ marginTop: "3px" }}
                                >
                                  {translations[language].loss}
                                </span>
                              </label>
                              <label className="MuiFormControlLabel-root results_btn results_btn_cancel">
                                <span
                                  className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root"
                                  aria-disabled="false"
                                >
                                  <span className="MuiIconButton-label">
                                    <input
                                      className="jss4 mr-2"
                                      name="battleResult"
                                      type="radio"
                                      defaultValue="cancelled"
                                      onClick={() => {
                                        setStatus("cancelled");
                                        // alert(
                                        //   "Are you sure you want to cancel? क्या आप वाकई इसे रद्द करना चाहते हैं?"
                                        // );
                                        handleShow();
                                      }}
                                      style={{ transform: "scale(1.5)" }}
                                    />
                                  </span>
                                  <span className="MuiTouchRipple-root" />
                                </span>
                                <span
                                  className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                                  style={{ marginTop: "3px" }}
                                >
                                  {translations[language].cancel}
                                </span>
                              </label>
                            </div>
                          </>
                        )}

                        {/* ================================= */}
                        {/* ========cancel model============ */}
                        {/* ================================= */}

                        <section className="game_result_submit_model">
                          <Modal
                            show={show}
                            onHide={handleClose}
                            className="cancel_popup_reason_popup"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                              <div>
                                <h3>We would like to know more</h3>
                                <h5>Select Reason for cancelling</h5>

                                {status !== null && status == "cancelled" && (
                                  <div>
                                    <Chip
                                      className="cancel_reason_btns_bg"
                                      label="No Room code"
                                      value="No Room code"
                                      id="reason1"
                                      onClick={() => {
                                        setSelectedReason("No Room code");
                                        setShowInput(false);
                                        handleReason1();
                                      }}
                                    />
                                    <Chip
                                      className="cancel_reason_btns_bg"
                                      label="Not joined"
                                      value="Not joined"
                                      id="reason2"
                                      onClick={() => {
                                        setSelectedReason("Not Joined");

                                        setShowInput(false);

                                        handleReason2();
                                      }}
                                    />
                                    <Chip
                                      className="cancel_reason_btns_bg"
                                      label="Not Playing"
                                      value="Not Playing"
                                      id="reason3"
                                      onClick={() => {
                                        setSelectedReason("Not Playing");

                                        setShowInput(false);
                                        handleReason3();
                                      }}
                                    />
                                    <Chip
                                      className="cancel_reason_btns_bg"
                                      label="Dont Want to Play"
                                      value="Dont Want to Play"
                                      id="reason3"
                                      onClick={() => {
                                        setSelectedReason("Dont Want Play");

                                        setShowInput(false);
                                        handleReason4();
                                      }}
                                    />
                                    <Chip
                                      className="cancel_reason_btns_bg"
                                      label="Opponent Abusing"
                                      value="Opponent Abusing"
                                      id="reason3"
                                      onClick={() => {
                                        setSelectedReason("Opponent Abusing");

                                        setShowInput(false);
                                        handleReason5();
                                      }}
                                    />
                                    <Chip
                                      className="cancel_reason_btns_bg"
                                      label="Game not Started"
                                      value="Game not Started"
                                      id="reason3"
                                      onClick={() => {
                                        setSelectedReason("Game Not Started");

                                        setShowInput(false);
                                        handleReason6();
                                      }}
                                    />
                                    <Chip
                                      className="cancel_reason_btns_bg"
                                      label="Others"
                                      value="Others"
                                      id="reason3"
                                      onClick={() => {
                                        setShowInput(true);
                                      }}
                                    />
                                  </div>
                                )}
                                {showInput && (
                                  <div class="form-group mt-3">
                                    <textarea
                                      class="form-control border-secondary"
                                      name=""
                                      placeholder="Write your Reasone......"
                                      id=""
                                      rows="3"
                                      onChange={(e) => {
                                        setSelectedReason("Not Playing");

                                        setReason(e.target.value);
                                      }}
                                    ></textarea>
                                  </div>
                                )}

                                {fecthStatus == null &&
                                  fecthStatus == undefined && (
                                    <input
                                      type="submit"
                                      className="btn btn-danger mt-3 text-white results_btn w-100"
                                      id="post"
                                      onClick={(e) => {
                                        if (selectedReason) {
                                          Result(e);
                                        } else {
                                          Swal.fire({
                                            position: "center",
                                            icon: "error",
                                            type: "error",
                                            title: "Please Select Reasone",
                                            showConfirmButton: true,
                                          });
                                        }
                                      }}
                                    />
                                  )}
                              </div>
                            </Modal.Body>
                          </Modal>
                        </section>

                        {/* ================================= */}
                        {/* ========WON model============ */}
                        {/* ================================= */}

                        <section className="win_modal">
                          <Modal
                            show={show_won}
                            onHide={handleClose_won}
                            className="cancel_popup_reason_popup"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                              <div>
                                <h3>Upload Result</h3>

                                {status !== null &&
                                  status !== "cancelled" &&
                                  status !== "lose" && (
                                    <div
                                      className={`${css.doc_upload} mt-4 upload_ss_btn`}
                                    >
                                      {/* <input type="file" onChange={(e) => setScrnshot(e.target.files[0])} accept="image/*" required /> */}
                                      {/* ADDED BY TEAM */}
                                      <input
                                        type="file"
                                        onChange={handleChange}
                                        accept="image/*"
                                        required
                                      />
                                      {/* ADDED BY TEAM */}
                                      {!scrnshot && (
                                        <div className="cxy position-absolute ">
                                          <i
                                            class="fa-solid fa-arrow-up mr-2"
                                            style={{ color: "#fff" }}
                                          ></i>
                                          <div
                                            className={`${css.sideNav_text} upload_ss_btn_name`}
                                          >
                                            Upload screenshot.
                                          </div>
                                        </div>
                                      )}
                                      {scrnshot && (
                                        <div className={css.uploaded}>
                                          <img
                                            src="/images/file-icon.png"
                                            width="26px"
                                            alt=""
                                            style={{ marginRight: "20px" }}
                                          />
                                          <div
                                            className="d-flex flex-column"
                                            style={{ width: "80%" }}
                                          >
                                            <div
                                              className={`${css.name} `}
                                              style={{ color: "#fff" }}
                                            >
                                              {scrnshot.name}
                                            </div>
                                            <div className={css.size}>
                                              {(
                                                scrnshot.size /
                                                1024 /
                                                1024
                                              ).toFixed(2)}{" "}
                                              MB
                                            </div>
                                          </div>
                                          <div className="image-block">
                                            <img
                                              src="/images/global-cross.png"
                                              width="10px"
                                              alt=""
                                              onClick={() => {
                                                clearImage();
                                                handleClose_won();
                                              }}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                {/* <div style={{ width: "100%", marginTop: "20px" }}>
                                <img
                                  src={scrnshot1}
                                  style={{ width: "100%" }}
                                  className="screenshot_img"
                                />
                              </div> */}

                                {fecthStatus == null &&
                                  fecthStatus == undefined && (
                                    <input
                                      type="submit"
                                      value="Post Submit"
                                      className="btn  mt-3 text-white results_btn w-100"
                                      id="post"
                                      onClick={(e) => {
                                        Result(e);
                                      }}
                                      style={{ backgroundColor: "#13f513" }}
                                      disabled={!scrnshot}
                                    />
                                  )}
                              </div>
                            </Modal.Body>
                          </Modal>
                        </section>

                        {/* ================================= */}
                        {/* ========lost model============ */}
                        {/* ================================= */}

                        <section className="">
                          <Modal
                            show={show_lost}
                            onHide={handleClose_lost}
                            className="cancel_popup_reason_popup"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                              <div>
                                <h3>Are you sure you Lost this game?</h3>
                                <h3>
                                  क्या आप निश्चित हैं कि आप यह गेम हार गए हैं?
                                </h3>

                                <div className="checkbox_game">
                                  <input
                                    type="checkbox"
                                    id="loast"
                                    name="loast"
                                    value="Yes, lost"
                                    checked={isChecked}
                                    onChange={handleChangeCheckbox}
                                  />
                                  <label for="loast"> Yes, I Confirm</label>
                                </div>
                                <div class="d-flex">
                                  {fecthStatus == null &&
                                    fecthStatus == undefined && (
                                      <input
                                        disabled={!isChecked}
                                        type="submit"
                                        value="Yes, I Lost"
                                        className="btn btn-danger rounded mr-2 mt-2 text-white results_btn w-100"
                                        id="post"
                                        // onClick={handleClose_lost}
                                        onClick={(e) => {
                                          Result(e);
                                        }}
                                      />
                                    )}
                                  <button
                                    onClick={handleClose_lost}
                                    className="no_lost_game_btn"
                                    style={{
                                      backgroundColor: "blue",
                                      borderColor: "blue",
                                      color: "#fff",
                                    }}
                                  >
                                    No{" "}
                                  </button>
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </section>
                      </form>
                    </div>

                    {Game.Game_type === "LudoPrime" ? (
                      <div
                        className="rules"
                        style={{
                          ...styles,
                          display: "grid",
                          justifyContent: "center",
                        }}
                      >
                        <h5
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          Play ludo game in Prime Ludo App
                        </h5>
                        <div className="m-2 d-flex">
                          <a
                            href="https://primeludo.in/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button className="Download_button">
                              <img
                                className="AppImage"
                                src="/mainimage/primeLudo.jpg"
                                alt=""
                              />
                              <span className="AppClick">Click Here</span>{" "}
                            </button>
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="rules"
                        style={{
                          ...styles,
                          display: "grid",
                          justifyContent: "center",
                        }}
                      >
                        <h5
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          Play ludo game in Ludo King App
                        </h5>
                        <div className="m-2 d-flex">
                          <a href="https://ludokingnew.page.link/81RNFHkNENoqCtBN6">
                            <img
                              className="mr-2"
                              src="/images/app-store.jpeg"
                            />
                          </a>
                          <a href="https://ludokingnew.page.link/FPqXbMi3W7tAvj5t9">
                            <img src="/images/google-play.jpeg" />
                          </a>
                        </div>
                      </div>
                    )}

                    <div className="rules" style={styles}>
                      <ol className="list-group list-group-numbered">
                        {Game.Game_type === "Ludo Popular" ||
                        Game.Game_type === "LudoSnake" ||
                        Game.Game_type === "ludoClassicManual" ? (
                          <li className="list-group-item" style={styles}>
                            {`यह ${Game.Game_type} है यहा आप SET ROOM CODE मे दुसरे गेम के कोड देते है या शेयर करते है तो टेबल कैंसल कर दिया जाएगा और Penalty लगा दी जाए`}
                          </li>
                        ) : (
                          ""
                        )}
                      </ol>
                    </div>

                    <div className="rules" style={styles}>
                      <h2 className="profile_headings ml-2" style={styles}>
                        {translations[language].rule}
                      </h2>
                      <ol
                        className="list-group list-group-numbered"
                        style={styles}
                      >
                        <li className="list-group-item" style={styles}>
                          Record every game while playing.
                        </li>
                        <li className="list-group-item" style={styles}>
                          For cancellation of game, video proof is necessary.
                        </li>
                        <li className="list-group-item " style={styles}>
                          ◉ महत्वपूर्ण नोट:कृपया गलत गेम परिणाम अपलोड न करें,
                          अन्यथा आपके वॉलेट बैलेंस पर penalty लगाई जायगी। गलत
                          रिजल्ट अपडेट करने पर 50 रुपये पेनल्टी लगेगी।
                        </li>
                        <li className="list-group-item " style={styles}>
                          ◉ महत्वपूर्ण नोट: यदि आप गेम के परिणामों को अपडेट नहीं
                          करते हैं, तो आपके वॉलेट बैलेंस पर जुर्माना लगाया
                          जाएगा। रिजल्ट अपडेट नहीं करने पर 25 रुपये पेनल्टी
                          लगेगी।
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {Boolean(submitProcess) && (
              <div className="loaderReturn" style={{ zIndex: "99" }}>
                <img
                  src={"/images/LandingPage_img/loader1.gif"}
                  style={{ width: "20%" }}
                />
              </div>
            )}
          </div>
        </div>
      )}

      <div class="divider-y"></div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </>
  );
}
