import axios from "axios";
import CryptoJS from 'crypto-js';
import {createNewHmac , verifyHmac} from './hmacUtils'


const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {

  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}

// replace with your API base URL

const CALLBACK_HASH_SECRET_KEY = 'xmrygl9awugdwaam0vvpw3n6bvhofabnxscle8jwj9odm4rbpr9fyqd7vy31dmkq'; // Replace with your actual secret key


// API request function
export const apiRequest = async (endpoint, method, data = null, headers = {}) => {
  const url = `${baseUrl}${endpoint}`; // Ensure the URL is constructed properly

const access_token = localStorage.getItem('token')
  const options = {
    method: method,
    headers: {
      ...(data instanceof FormData ? {} : { "Content-Type": "application/json" }),
      ...headers,
    },
  };


 // Add userHmac only for GET and DELETE requests
if ((method === 'GET' || method === 'DELETE' || method === 'PUT' )&& data == null) {
  const hmacPayload = { token: access_token, url };
  const hmac = createNewHmac(hmacPayload);
  // Add userHmac to headers
  options.headers.userHmac = hmac;

  return axios.get(`${url}`, options);
}

  if (data) {
   
    const hmac = createNewHmac(data);
    if (data instanceof FormData) {
      data.append('userHmac', hmac);
    } else {
      data.userHmac = hmac;
    }
    options.data = data; // Pass the data directly without JSON.stringify
  }



  try {
   
    const response = await axios(url, options);

    if (!response) {
      if (response.status == 401) {
        localStorage.removeItem("token");
            localStorage.removeItem("token");
            window.location.reload();
            setTimeout(() => {}, 500);
      }
      throw new Error(`Error: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
 }
};
